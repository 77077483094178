body {
  margin: 0;
  padding: 0;
}

a {
  color: #000;
}

.container {
  max-width: 1440px;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}

ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}

ul.timeline .timeline-date {
  margin: 30px 0;
  padding-left: 20px;
}

ul.timeline .timeline-date:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid rgb(0, 82, 204);;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

.date {
  font-weight: bold;
  color: rgb(0, 82, 204);;
  margin-bottom: 10px;
}

.releases {
  list-style-type: none;
  padding: 0;
}

.release {
  margin-bottom: 10px;
}

.release-version {
  margin-bottom: 5px;
}

.milestone{
  margin-bottom: 5px;
}
.milestone .milestone-title {
  margin: 0;
  color: #000000ba;
  font-size: 18px;
  font-weight: 600;
} 
.milestone .milestone-description {
  margin: 0;
  font-size: 15px;
} 

.release-issues {
  padding: 0;
  list-style-type: none;
}

.release-issue {
  font-size: 14px;
  align-items: center;
  padding: 5px 0;
}

.release-issue a {
  color: #000;
}

.release-issue img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}